import React from "react";
import { Link } from "gatsby";

export default function Header({ nav, title }) {
  const base =
    "w-full px-2 rounded-sm inline-block font-semibold hover:text-white hover:no-underline sm:px-4 sm:rounded md:rounded-none md:h-full";
  const baseClass = `${base} text-gray-400 sm:hover:bg-gray-800`;
  const activeClass = `${base} bg-red-800 text-white`;

  return (
    <header className="bg-gray-900 text-center">
      <nav className="p-1 md:container md:mx-auto md:p-0 md:flex md:items-stretch">
        <div className="p-2 bg-gray-700 text-center md:inline-block md:px-4 md:flex">
          <Link
            className="text-xl text-white hover:text-white hover:no-underline"
            to="/"
          >
            {title}
          </Link>
        </div>

        <q className="block p-2 text-sm italic text-white md:flex mt-auto">
          I code in my jammies!
        </q>

        <div className="leading-10 md:inline-block md:px-4 md:flex md:ml-auto">
          <Link
            to="/blog/"
            className={nav === "blog" ? activeClass : baseClass}
          >
            Blog
          </Link>
          <Link
            to="/about/"
            className={nav === "about" ? activeClass : baseClass}
          >
            About
          </Link>
          <a className={baseClass} href="/rss.xml">
            RSS
          </a>
        </div>
      </nav>
    </header>
  );
}
