import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import RelatedPosts from "./related-posts";
import Header from "./header";
import About from "./about";
import Tag from "../components/tag";

export default function Layout({
  nav,
  pageTitle,
  pageDescription,
  pageCanonical,
  relatedTo,
  children,
}) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
        tagsGroup: allMdx {
          group(field: frontmatter___tags) {
            tag: fieldValue
            totalCount
          }
        }
        # this is not ideal as it pulls all posts down
        # it will get really slow as the number of posts increase
        allPosts: allMdx {
          nodes {
            fields {
              slug
            }
            frontmatter {
              title
              date(formatString: "MMMM DD, YYYY")
              tags
            }
          }
        }
      }
    `
  );

  const tags = data.tagsGroup.group
    .sort((a, b) => b.totalCount - a.totalCount)
    .slice(0, 10);
  const siteMetadata = data.site.siteMetadata;

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={pageDescription ? pageDescription : siteMetadata.description}
        />
        {pageCanonical && <link rel="canonical" href={pageCanonical} />}
        <title>
          {pageTitle ? `${pageTitle} | ` : ""}
          {siteMetadata.title}
        </title>
      </Helmet>

      <Header nav={nav} title={siteMetadata.title} />

      <div className="mx-auto p-4 md:container grid grid-cols-1 md:grid-cols-6">
        <main className="md:col-span-4">{children}</main>

        <div className="pt-6 md:pt-0 md:pl-6 md:col-span-2">
          <About />

          <aside aria-label="Popular Tags">
            <h3>Popular Tags</h3>
            <ul>
              {tags.map((tag) => (
                <li className="pb-3" key={tag.tag}>
                  <Tag tag={tag.tag} count={tag.totalCount} />
                </li>
              ))}
            </ul>
          </aside>

          {relatedTo && (
            <RelatedPosts
              relatedTo={relatedTo}
              allPosts={data.allPosts.nodes}
            />
          )}
        </div>
      </div>

      <footer className="container mx-auto py-2 text-center border-t">
        <p>&copy;2018 Donald Chea, Pyjama Coder</p>
      </footer>
    </div>
  );
}
