import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

export default function About() {
  return (
    <aside aria-label="About" className="pt-6 border-t md:pt-0 md:border-0">
      <StaticImage
        src="../images/avatar.png"
        alt="Pyjama coders drink coffee"
      />
      <div>
        <h3>About the Author</h3>
        <p>
          <Link to="/about/" rel="me">
            Donald Chea
          </Link>{" "}
          is a developer living in Toronto, Canada, who enjoys spending time
          learning about the web.
        </p>
        <p>
          With his trusty Vim editor and SSH connections, he can cause trouble
          in the comfort of his PJs.
        </p>
        <p>
          When he's not coding in his jammies, he's this{" "}
          <a
            href="https://donaldchea.com"
            title="He's not a pyjama coder (I think)"
          >
            guy over here
          </a>
          .
        </p>
      </div>
      <a href="https://github.com/donaldducky" title="Github">
        <StaticImage src="../images/github.png" alt="github" />
      </a>{" "}
      <a href="https://twitter.com/donaldchea" title="Twitter">
        <StaticImage src="../images/twitter.png" alt="twitter" />
      </a>
    </aside>
  );
}
