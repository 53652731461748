import * as React from "react";
import { Link } from "gatsby";
import kebabCase from "lodash/kebabCase";

export default function Tag({ tag, count }) {
  return (
    <>
      <Link
        to={`/tags/${kebabCase(tag)}/`}
        className="mx-1 text-xs font-semibold py-1 px-2 uppercase rounded text-black bg-gray-300"
      >
        {tag}
        {count && ` (${count})`}
      </Link>
    </>
  );
}
