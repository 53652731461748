import React from "react";
import { Link } from "gatsby";

export default function RelatedPosts({ relatedTo, allPosts }) {
  const posts = allPosts
    .filter((p) => p.fields.slug !== relatedTo.slug)
    .map((p) => {
      p.score = p.frontmatter.tags.filter((x) =>
        relatedTo.tags.includes(x)
      ).length;

      return p;
    })
    .filter((p) => p.score > 0)
    .sort((a, b) => {
      const score = a.score - b.score;

      if (score !== 0) {
        return score;
      }

      // sort by date
      const ad = new Date(a.frontmatter.date);
      const bd = new Date(b.frontmatter.date);
      if (ad < bd) {
        return 1;
      } else if (ad > bd) {
        return -1;
      } else {
        return 0;
      }
    })
    .slice(0, 5);

  return (
    <aside aria-label="Related Posts">
      <h3>Related Posts</h3>
      <ul>
        {posts.map((post) => (
          <li key={post.fields.slug} className="pb-3">
            <Link to={post.fields.slug}>{post.frontmatter.title}</Link> on{" "}
            <span>{post.frontmatter.date}</span>
          </li>
        ))}
      </ul>
    </aside>
  );
}
